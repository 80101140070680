/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";
import ContactForm from "./sub-component/ContactForm";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    scrollMargin: "5.5rem 0rem 0rem 0rem",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 72rem 1rem 72rem",
    },
    margin: "0rem 0rem 2rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 1rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "1rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0rem 3rem 0rem",
    gap: "2rem",
  },
  heading: {
    color: "#74378E",
    background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    margin: "0rem 0rem 2rem 0rem",
    fontWeight: 400,
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
      lineHeight: "1.75rem",
    },
  },
  iframe: {
    width: "50%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "15rem",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      height: "22rem",
    },
    [theme.breakpoints.only("md")]: {
      height: "33rem",
    },
    [theme.breakpoints.only("lg")]: {
      height: "33rem",
    },
    [theme.breakpoints.only("xl")]: {
      height: "33rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "32rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 920)]: {
      height: "31rem",
    },
  },
}));

const ContactUs = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div id="contactus" className={classes.megaContainer}>
        <Typography variant="h3" component="h3" className={classes.heading}>
          Contact us
        </Typography>
        <div className={classes.sectionContainer}>
          <ContactForm source={props.source ? props.source : "Home Page"} />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3888.93072124825!2d77.63931151482137!3d12.912174190894879!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156a6cc28447%3A0xf9a4bc41aed205b0!2sHeavie%20Developers!5e0!3m2!1sen!2sin!4v1638352117695!5m2!1sen!2sin"
            width={500}
            height={350}
            className={classes.iframe}
            frameBorder="0"
            aria-hidden="false"
            tabIndex="0"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
